import React, { PureComponent } from 'react'
import { Button, Alert, Modal, Tabs, Spin } from 'antd'
import { connect } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import { LoadingOutlined, EditOutlined } from '@ant-design/icons'

import config from '../config'
import TabLayout from './TabLayout'
import {
  initCard,
  createCard,
  getCard,
  updateCard,
  setValidation,
  updateModal,
  resetNewId,
} from '../redux/actions/SimpleCardActions'
import getFieldsFromCol from '../utils/getFieldsFromCol'
import getFieldsConfigFromCol from '../utils/getFieldsConfigFromCol'

const { TabPane } = Tabs

class SimpleCardLayout extends PureComponent {
  constructor() {
    super()
    this.validateCard = this.validateCard.bind(this)
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    const id = this.props.id

    this.props.updateModal(true, id)

    let cardConfig = config.pages.filter(item => item.url === this.props.cardUrl)[0]

    if (id === 'create') {
      let fields = {}
      if (cardConfig.tabs) {
        cardConfig.tabs.forEach(tab => {
          fields = {
            ...fields,
            ...getFieldsFromCol(tab, 'left'),
            ...getFieldsFromCol(tab, 'right'),
            ...getFieldsFromCol(tab, 'both'),
          }
        });
      }
      this.props.initCard(fields, cardConfig.url)
    } else {
      this.props.getCard(cardConfig.getApiUrl(id), cardConfig.url)
    }
  }

  componentDidUpdate() {
    const { simpleCard, id } = this.props
    let cardConfig = config.pages.filter(item => item.url === this.props.cardUrl)[0]

    if (
      !simpleCard.isOpen &&
      simpleCard.newId &&
      simpleCard.url === cardConfig.url &&
      id === simpleCard.openId
    ) {
      if (id === 'create') {
        this.props.onCreate(simpleCard.newId)
      } else if (simpleCard.newId === id) {
        this.props.onEdit()
      }
      this.props.resetNewId()
    }
  }

  validateCard() {
    const { simpleCard } = this.props
    let cardConfig = config.pages.filter(item => item.url === this.props.cardUrl)[0]

    let items = []
    if (cardConfig.tabs) {
      cardConfig.tabs.forEach(tab => {
        items = [
          ...items,
          ...getFieldsConfigFromCol(tab, 'left'),
          ...getFieldsConfigFromCol(tab, 'right'),
          ...getFieldsConfigFromCol(tab, 'both'),
        ]
      })
    }

    let errorFields = []
    items.forEach(item => {
      if (
        item.required && !simpleCard.fields[item.key] ||
        item.checkValue && !item.checkValue(simpleCard.fields)
      ) {
        errorFields.push(item.key)
      }
    })

    this.props.setValidation(errorFields)

    return errorFields
  }

  render() {
    const { user, simpleCard, id } = this.props
    let cardConfig = config.pages.filter(item => item.url === this.props.cardUrl)[0]

    if (cardConfig.access.indexOf(user.type) === -1 && cardConfig.access[0] !== '*') {
      return null
    }

    const tabs = cardConfig.tabs.filter(item => {
      let left = item.children.left.filter(field => field.required || field.showInSmallCard)
      let right = item.children.right.filter(field => field.required || field.showInSmallCard)
      let both = item.children.both.filter(field => field.required || field.showInSmallCard)
      return left.length > 0 || right.left > 0 || both.length > 0
    }).map(item => (
      <TabPane tab={item.title} key={item.key}>
        <TabLayout tab={item} mode="simple" />
      </TabPane>
    ))

    const antIcon = <LoadingOutlined style={{ fontSize: 40 }} />

    return (
      <>
        <Button
          type={this.props.buttonType}
          shape="circle"
          icon={id === 'create' ? <PlusOutlined /> : <EditOutlined />}
          onClick={this.openModal}
        />
        <Modal
          title={cardConfig.getTitle(id)}
          visible={
            simpleCard.isOpen &&
            simpleCard.url === cardConfig.url &&
            simpleCard.openId === id
          }
          okText="Сохранить"
          onCancel={() => this.props.updateModal(false)}
          onOk={() => {
            if (this.validateCard().length === 0) {
              if (id === 'create') {
                this.props.createCard(cardConfig.getApiUrl(id), simpleCard.fields)
              } else {
                this.props.updateCard(cardConfig.getApiUrl(id), simpleCard.fields)
              }
            }
          }}
          destroyOnClose={true}
        >
          <Spin spinning={simpleCard.isLoading} indicator={antIcon}>
            {simpleCard.errorFields.length > 0 && (
              <Alert
                message="Некорректно заполнены поля"
                type="error"
                style={{ marginTop: '0', marginBottom: '24px' }}
              />
            )}
            <Tabs
              style={{ marginTop: '-24px' }}
            >
              {tabs}
            </Tabs>
          </Spin>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    simpleCard: store.simpleCard,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initCard: (fields, url) => dispatch(initCard(fields, url)),
    createCard: (url, data) => dispatch(createCard(url, data)),
    getCard: (url, cardUrl) => dispatch(getCard(url, cardUrl)),
    updateCard: (url, data) => dispatch(updateCard(url, data)),
    setValidation: (errorFields) => dispatch(setValidation(errorFields)),
    updateModal: (isOpen, openId = null) => dispatch(updateModal(isOpen, openId)),
    resetNewId: () => dispatch(resetNewId()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleCardLayout)