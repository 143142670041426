export const TYPES = {
  0: 'Игра',
  1: 'Рекомендация',
  2: 'Наблюдение',
  3: 'Комментарий',
}

const config = {
  url: '/recommendations',
  title: 'Рекомендации',
  endpoint: '/admin-search?filter=recommendations',
  type: 'table',
  getUrl: id => `/recommendation/${id}`,
  getApiUrl: id => `/recommendations/${id}`,
  search: true,
  key: 'recommendations',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'name',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.name,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'type_number',
      width: 150,
      title: 'Тип',
      sorter: true,
      isViewByDefault: true,
      getValue: data => TYPES[data.type_number],
      edit: {
        component: 'StaticSelect',
        options: TYPES,
        placeholder: 'Выберите тип',
      },
    },
  ],
  filters: [
    {
      key: 'name',
      component: 'Input',
      placeholder: 'Введите название',
      maxLength: 255,
    },
    {
      key: 'type_number',
      component: 'StaticSelect',
      options: TYPES,
      placeholder: 'Выберите тип',
      mode: 'multiple'
    },
  ],
}

export default config
