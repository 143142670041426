import { TYPES } from "./recommendationsTable"

const config = {
  url: '/recommendation/:id',
  getTitle: id => id === 'create' ? 'Создание рекомендации' : 'Редактирование рекомендации',
  getApiUrl: id => id === 'create' ? '/recommendations' : `/recommendations/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [],
        right: [],
        both: [
          {
            key: 'name',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'type_number',
            title: 'Тип',
            component: 'StaticSelect',
            options: TYPES,
            placeholder: 'Выберите тип',
            required: false,
            defaultValue: "0",
          },
          {
            key: 'description',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    }
  ]
}

export default config
