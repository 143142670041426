const config = {
  url: '/communication-question/:id',
  getTitle: id => id === 'create' ? 'Создание вопроса' : 'Редактирование вопроса',
  getApiUrl: id => id === 'create' ? '/communication-questions' : `/communication-questions/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [],
        right: [],
        both: [
          {
            key: 'name',
            title: 'Вопрос',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'description',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
          {
            key: 'recommendation',
            title: 'Рекомендация',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
          {
            key: 'go_to_navigator_if_yes',
            title: 'Переход к навигатору, если ответ да',
            component: 'Switch',
            defaultValue: 0,
          },
        ],
      }
    }
  ]
}

export default config
