import { LEVELS } from "./gdsStagesTable"

const config = {
  url: '/gds-stage/:id',
  getTitle: id => id === 'create' ? 'Создание этапа' : 'Редактирование этапа',
  getApiUrl: id => id === 'create' ? '/gds-stages' : `/gds-stages/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [],
        right: [],
        both: [
          {
            key: 'number',
            title: 'Номер',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'name',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'level',
            title: 'Уровень',
            component: 'StaticSelect',
            options: LEVELS,
            placeholder: 'Выберите уровень',
            required: true,
            defaultValue: "1",
          },
          {
            key: 'question_ids',
            title: 'Вопросы',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=gds_questions&sort[field]=number&sort[type]=asc&limit=100',
            filter: 'gds_questions',
            placeholder: 'Выберите',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.id,
            required: false,
            allowClear: true,
            relatedCard: '/gds-question/:id',
            hideSelect: true,
            template: {
              allowEdit: true,
              allowDelete: true,
              getTemplateValue: data => (
                <div>
                  {data.number}. {data.name}
                </div>
              ),
            }
          },
        ],
      }
    }
  ]
}

export default config
