
import pagesTable from './pagesTable'
import pagesCard from './pagesCard'
import usersTable from './usersTable'
import usersCard from './usersCard'
import examplesTable from './examplesTable'
import examplesCard from './examplesCard'
import tagsTable from './tagsTable'
import tagsCard from './tagsCard'
import wordsTable from './wordsTable'
import wordsCard from './wordsCard'
import recommendationsTable from './recommendationsTable'
import recommendationsCard from './recommendationsCard'
import communicationQuestionsTable from './communicationQuestionsTable'
import communicationQuestionsCard from './communicationQuestionsCard'
import gdsStagesTable from './gdsStagesTable'
import gdsStagesCard from './gdsStagesCard'
import gdsQuestionsCard from './gdsQuestionsCard'

const pages = [
  pagesTable,
  pagesCard,
  usersTable,
  usersCard,
  examplesTable,
  examplesCard,
  tagsTable,
  tagsCard,
  wordsTable,
  wordsCard,
  recommendationsTable,
  recommendationsCard,
  communicationQuestionsTable,
  communicationQuestionsCard,
  gdsStagesTable,
  gdsStagesCard,
  gdsQuestionsCard,
]

const menu = [
  {
    title: 'Страницы',
    page: pagesTable,
  },
  {
    title: 'Словарь',
    page: wordsTable,
  },
  {
    title: 'Рекомендации',
    page: recommendationsTable,
  },
  {
    title: 'Коммуникация',
    page: communicationQuestionsTable,
  },
  {
    title: 'Этапы',
    page: gdsStagesTable,
  },
  {
    title: 'Пользователи',
    page: usersTable,
  },
]

const config = {
  pages,
  menu
}

export default config