const config = {
  url: '/word/:id',
  getTitle: id => id === 'create' ? 'Создание слова' : 'Редактирование слова',
  getApiUrl: id => id === 'create' ? '/words' : `/words/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [],
        right: [],
        both: [
          {
            key: 'name',
            title: 'Слово',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'definition',
            title: 'Определение',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    }
  ]
}

export default config
