import React, { PureComponent } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build'
import axios from 'axios'
import { connect } from 'react-redux'

let userAccessToken = ''

class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    const url = process.env.REACT_APP_API_HOST

    return this.loader.file
      .then(uploadedFile => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          data.append('file', uploadedFile);

          axios({
            url: `${url}/storage/store-image-ckeditor`,
            method: 'post',
            data,
            headers: {
              'Authorization': `Bearer ${userAccessToken}`,
            },
            withCredentials: false
          }).then(response => {
            if (response.data.uploaded) {
              resolve({
                default: response.data.url
              });
            } else {
              reject(response.data.message);
            }
          }).catch(response => {
            reject('Во время загрузки произошла ошибка');
          });

        });
      });
  }

  abort() {
    // Reject promise returned from upload() method.
  }
}

class CKEditorField extends PureComponent {
  componentDidUpdate() {
    userAccessToken = this.props.user.attributes.token
  }

  render() {
    const editorConfig = {
      language: "ru",
      fontBackgroundColor: {
        colors: [
          {
            color: "#ff8800",
            label: "Оранжевый",
          },
          {
            color: "#1d92b7",
            label: "Синий",
          },
          {
            color: "#00FF7F",
            label: "Зеленый",
          },
          {
            color: "#fbcc2d",
            label: "Желтый",
          },
        ],
      },
      fontColor: {
        colors: [
          {
            color: '#499AC8',
            label: 'Синий'
          },
          {
            color: '#FF8800',
            label: 'Оранжевый'
          },
        ]
      },
      toolbar: {
        items: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "imageInsert",
          "fontColor",
          "fontBackgroundColor",
          "insertTable",
          "mediaEmbed",
          "blockquote",
          "|",
          'htmlEmbed',
          "sourceEditing",
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Параграф",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: "h2",
            title: "Заголовок 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: "h3",
            title: "Заголовок 2",
            class: "ck-heading_heading2",
          },
          {
            model: "heading3",
            view: "h4",
            title: "Заголовок 3",
            class: "ck-heading_heading3",
          },
        ],
      },
      image: {
        toolbar: [
          "imageTextAlternative",
          "|",
          "imageStyle:alignLeft",
          "imageStyle:block",
          "imageStyle:alignRight",
        ],

        styles: ["full", "alignLeft", "alignRight"],
      },
      table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
      },
      mediaEmbed: {
        previewsInData: true,
      },
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true
          }
        ]
      },
      plugins: [
        'Essentials',
        'Autoformat',
        'Bold',
        'Italic',
        'Strikethrough',
        'Subscript',
        'Superscript',
        'Alignment',
        'BlockQuote',
        'CKFinder',
        'CloudServices',
        'FontColor',
        'FontBackgroundColor',
        'Heading',
        'Image',
        'ImageCaption',
        'ImageStyle',
        'ImageToolbar',
        'ImageUpload',
        'ImageInsert',
        'Indent',
        'Link',
        'List',
        'MediaEmbed',
        'Paragraph',
        'PasteFromOffice',
        'Table',
        'TableToolbar',
        'RemoveFormat',
        'HtmlEmbed',
        'SourceEditing',
      ],
      placeholder: this.props.placeholder,
      /*
      ckfinder: { 
        uploadUrl:
          "/admin/ajax.php?action=controller&type=data&name=edit.class&handler=file-download-handler.php",
      },
      */
    };

    return (
      <CKEditor
        editor={Editor}
        config={editorConfig}
        data={this.props.value}
        onReady={(editor) => {
          editor.editing.view.change((writer) => {
            writer.setStyle(
              this.props.isFixedHeight ? "height" : "min-height",
              this.props.height,
              editor.editing.view.document.getRoot()
            );
          });

          editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
            return new UploadAdapter(loader);
          };
        }}
        onChange={(event, editor) => {
          const data = editor.getData()
          return this.props.onChange ?
            this.props.onChange(this.props.name, data ? data : '<p></p>') :
            null
        }}
        onBlur={(event, editor) => {
          const data = editor.getData()
          return this.props.onSave ?
            this.props.onSave(this.props.name, data ? data : '<p></p>') :
            null
        }}

      />
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
  }
}

export default connect(mapStateToProps)(CKEditorField)