export const LEVELS = {
  1: 'Первый',
  2: 'Второй',
}

const config = {
  url: '/gds-stages',
  title: 'Этапы',
  endpoint: '/admin-search?filter=gds_stages',
  type: 'table',
  getUrl: id => `/gds-stage/${id}`,
  getApiUrl: id => `/gds-stages/${id}`,
  search: true,
  key: 'words',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'number',
      width: 150,
      title: 'Номер',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.number,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'name',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.name,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'level',
      width: 150,
      title: 'Уровень',
      sorter: true,
      isViewByDefault: true,
      getValue: data => LEVELS[data.level],
      edit: {
        component: 'StaticSelect',
        options: LEVELS,
        placeholder: 'Выберите тип',
        required: true
      },
    },
  ],
  filters: [],
}

export default config
