import React, { PureComponent } from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'

import getField from '../utils/getField'
import { setField } from '../redux/actions/CardActions'
import { setField as setSimpleCardField } from '../redux/actions/SimpleCardActions'

class TabLayout extends PureComponent {
  constructor() {
    super()
    this.getFieldsForCol = this.getFieldsForCol.bind(this)
  }

  getFieldsForCol(col) {
    const { tab, mode } = this.props
    let fields = []

    const card = mode === 'simple' ? this.props.simpleCard : this.props.card

    if (tab.children[col]) {
      fields = tab.children[col]
        .filter(item => (mode === "simple" ? item.required || item.showInSmallCard : true))
        .map(item => {
          return (
            <div key={item.key} className={'Tab__label'}>
              <div className={'Tab__label-title ' + (item.required ? 'Tab__label-title-required' : '')}>
                {item.title}
              </div>
              {getField({
                ...item,
                key: item.key,
                value: card.fields[item.key] ? card.fields[item.key] : item.defaultValue,
                onChange: (key, value) => mode === 'simple' ? this.props.setSimpleCardField(key, value) : this.props.setField(key, value),
                status: card.errorFields.indexOf(item.key) !== -1 ? 'error' : undefined
              })}
            </div>
          )
        })
    }

    return fields
  }

  render() {
    const card = this.props.mode === 'simple' ? this.props.simpleCard : this.props.card

    if (card.isLoading) {
      return null
    }

    let left = this.getFieldsForCol('left')
    let right = this.getFieldsForCol('right')
    let both = this.getFieldsForCol('both')

    if (this.props.mode === 'simple') {
      both = [
        ...left,
        ...right,
        ...both
      ]
      left = []
      right = []
    }

    return (
      <Row gutter={[20, 0]}>
        {left.length > 0 && (
          <Col span={12}>
            {left}
          </Col>
        )}
        {right.length > 0 && (
          <Col span={12}>
            {right}
          </Col>
        )}
        {both.length > 0 && (
          <Col span={24}>
            {both}
          </Col>
        )}
      </Row>
    )
  }
}

const mapStateToProps = store => {
  return {
    card: store.card,
    simpleCard: store.simpleCard,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: (field, value) => dispatch(setField(field, value)),
    setSimpleCardField: (field, value) => dispatch(setSimpleCardField(field, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabLayout)