const config = {
  url: '/gds-question/:id',
  getTitle: id => id === 'create' ? 'Создание вопроса' : 'Редактирование вопроса',
  getApiUrl: id => id === 'create' ? '/gds-questions' : `/gds-questions/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [],
        right: [],
        both: [
          {
            key: 'number',
            title: 'Номер',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'name',
            title: 'Вопрос',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'description',
            title: 'Определение',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px',
            showInSmallCard: true,
          },
          {
            key: 'gds_substage_id',
            title: 'Подэтап',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=gds_stages&sort[field]=name&sort[type]=asc&limit=100&params[level][]=2',
            filter: 'gds_stages',
            keyName: 'name',
            placeholder: 'Выберите',
            search: false,
            mode: 'single',
            getOptionValue: data => data.name,
            required: false,
            showInSmallCard: true,
          },
          {
            key: 'recommendation_ids',
            title: 'Рекомендации',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=recommendations&sort[field]=name&sort[type]=asc&limit=50&strong_like=true',
            filter: 'recommendations',
            placeholder: 'Выберите рекомендации',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.name,
            required: false,
            showInSmallCard: true,
          },
        ],
      }
    }
  ]
}

export default config
